import { Container, Spinner } from 'react-bootstrap'
import styled from 'styled-components'

export interface SpinnerLoadingProps extends React.ComponentProps<any> {
  className?: string
}

const SpinnerLoading: React.FC<SpinnerLoadingProps> = ({ className }) => {
  return (
    <div className={className}>
      <Container className={`${className} spinner-container`}>
        <Spinner animation="border" variant="primary" />
      </Container>
    </div>
  )
}

export default styled(SpinnerLoading)`
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
