import { Container } from 'react-bootstrap'
import styled from 'styled-components'

export interface ErrorFetchingDataProps extends React.ComponentProps<any> {
  className?: string
}

const ErrorFetchingData: React.FC<ErrorFetchingDataProps> = ({ className }) => {
  return (
    <div className={className}>
      <Container className={`${className} error-fetching-data-container`}>
        <p>Oops! Something went wrong. Please refresh the page and try again.</p>
      </Container>
    </div>
  )
}

export default styled(ErrorFetchingData)`
  .error-fetching-data-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
