import styled from 'styled-components'

export interface NotFoundProps extends React.ComponentProps<any> {
  className?: string
}

const NotFound: React.FC<NotFoundProps> = ({ className }) => {
  return (
    <div className={className}>
      <h3>404 - Page Not Found!</h3>
      <a href="/">Go Home</a>
    </div>
  )
}

export default styled(NotFound)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
